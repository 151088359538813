import React from 'react'
import {observer} from "mobx-react-lite"
import __global from "../__global"
import {Button, Input, InputNumber, Select, Tag} from "antd"
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons"
import {uniqueId} from "lodash"

export const FormInput = observer(({
                                       type = 'text',
                                       disabled = __global.waitForAnswer,
                                       value = '',
                                       onChange = null,
                                       placeholder = '',
                                       className = null,
                                       controls = false,
                                       formatter = null,
                                       decimalSeparator = ",",
                                       accept = null,
                                       filesUploaded = false,
                                       placeholderUploaded = "Файл загружен",
                                       allowClear = true,
                                       defaultValue = '',
                                       restrictValue = false
                                   }) => {
    let render
    let id = uniqueId('input_')
    let inputProps = {
        id: id,
        type: type,
        disabled: disabled,
        placeholder: placeholder,
        allowClear: allowClear,
        defaultValue: defaultValue
    }

    if (!restrictValue) {
        inputProps['value'] = value
    }

    if (onChange) {
        inputProps['onChange'] = onChange
    }

    let inputClassName = 'form__input'
    if (className) {
        inputClassName += ` ${className}`
    }
    inputProps['className'] = inputClassName

    if (type === 'password') {
        inputProps['iconRender'] = visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)

        render = <Input.Password {...inputProps} />

    } else if (type === 'number') {
        delete inputProps.type
        delete inputProps.allowClear
        inputProps.controls = controls
        inputProps.formatter = formatter
        inputProps.decimalSeparator = decimalSeparator

        render = <InputNumber {...inputProps} />
    } else if (type === 'textarea') {
        inputProps['className'] += ' form__input-textarea'

        render = <Input.TextArea {...inputProps} />
    } else if (type === 'file') {
        delete inputProps.allowClear
        delete inputProps.placeholder
        delete inputProps.className

        if (accept) {
            inputProps['accept'] = accept
        }

        render = <div className="form__input-file">
            <input {...inputProps} />
            <label
                htmlFor={id}
                className={'form__button' + (filesUploaded ? ' form__button-green' : '')}
            >{filesUploaded ? placeholderUploaded : placeholder}</label>
        </div>
    } else {
        render = <Input {...inputProps} />
    }

    return render
})

export const FormButton = observer(({
                                        children,
                                        disabled = __global.waitForAnswer,
                                        onClick = null,
                                        className = null,
                                        htmlType = 'button'
                                    }) => {
    let buttonProps = {
        disabled: disabled,
        htmlType: htmlType
    }

    if (onClick) {
        buttonProps['onClick'] = onClick
    }

    let buttonClassName = 'form__button'
    if (className) {
        buttonClassName += ` ${className}`
    }
    buttonProps['className'] = buttonClassName

    return <Button {...buttonProps}>{children}</Button>
})

export const FormSelect = observer(({
                                        disabled = __global.waitForAnswer,
                                        onChange = null,
                                        placeholder = '',
                                        value = '',
                                        options = [],
                                        allowClear = false,
                                        mode = null,
                                        tagRender = null
                                    }) => {
    let selectProps = {
        disabled: disabled,
        placeholder: placeholder,
        value: value,
        options: options,
        className: 'form__select',
        allowClear: allowClear,
        showSearch: false
    }

    if (onChange) {
        selectProps['onChange'] = onChange
    }

    if (mode) {
        selectProps['mode'] = mode
    }

    if (tagRender) {
        selectProps['tagRender'] = tagRender
    } else {
        selectProps['tagRender'] = props => {
            const { label, value, closable, onClose } = props
            return (
                <Tag
                    closable={closable}
                    onClose={onClose}
                >
                    {props.label}
                </Tag>
            )
        }
    }

    return <Select {...selectProps} />
})
