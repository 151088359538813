import React, {useEffect} from 'react'
import {observer, Observer} from "mobx-react-lite"
import __global from "../../__global"
import __news from "./__news"
import {Col, Modal, Popconfirm, Popover, Row} from "antd"
import {FormButton, FormInput, FormSelect} from "../../_components/FormElements"
import {runInAction} from "mobx"
import GlobalTableBlock from "../../_components/_table/GlobalTable";

const NewsPage = observer(() => {
    useEffect(() => {
        __global.getList('news')
        __global.clearFilters()
    }, [])

    const columns = [
        {
            title: 'ID',
            key: 'id',
            sort: true
        },
        {
            title: 'Название',
            key: 'title',
            sort: true
        },
        {
            title: 'Текст',
            key: 'text',
            sort: true
        },
        {
            title: 'Изображение',
            key: 'image',
            type: 'customRender',
            render: (_, r) => {
                if (!_) return <></>

                return (<>
                    <img src={_.url} alt="" style={{maxWidth: 120}}/>
                </>)
            }
        },
        {
            title: 'Действия',
            key: 'actions',
            type: 'customRender',
            render: (_, r) => {
                return (
                    <Observer>
                        {() => (
                            <Row gutter={[9,9]} justify="end">
                                <Col>
                                    <FormButton
                                        onClick={() => __news.toggleModal(r.id)}
                                        className="form__button-orange form__button-small"
                                    >Редактировать</FormButton>
                                </Col>
                                <Col>
                                    <Popconfirm
                                        title={"Вы действительно хотите удалить новость " + r.id + "?"}
                                        onConfirm={_ => __news.delete(r.id)}
                                        okText="Да"
                                        cancelText="Нет"
                                    >
                                        <FormButton
                                            className="form__button-danger form__button-small"
                                        >Удалить</FormButton>
                                    </Popconfirm>
                                </Col>
                            </Row>
                        )}
                    </Observer>
                )
            }
        },
    ]

    return <>
        <div>
            <div className="workarea__section">
                <Row gutter={[9,9]}>
                    <Col>
                        <FormButton
                            onClick={() => __news.toggleModal()}
                            className="form__button-blue"
                        >Добавить новость</FormButton>
                    </Col>
                </Row>
            </div>

            <div className="workarea__section users">
                <Row gutter={[9, 9]}>
                    <Col xs={24}>
                        <GlobalTableBlock
                            title="Список новостей"
                            columns={columns}
                        />
                    </Col>
                </Row>
            </div>
        </div>

        <Modal
            title={__news.item.id > 0 ? 'Редактирование новости' : 'Создание новости'}
            open={__news.modalOpened}
            onCancel={_ => __news.toggleModal()}
            footer={null}
            className="workarea__modal"
        >
            <Row gutter={[9, 9]}>
                <Col xs={24}>
                    <FormInput
                        onChange={e => runInAction(() => __news.item.title = e.target.value)}
                        value={__news.item.title}
                        placeholder="Название"
                    />
                </Col>

                <Col xs={24}>
                    <FormInput
                        onChange={e => runInAction(() => __news.item.text = e.target.value)}
                        value={__news.item.text}
                        placeholder="Текст"
                    />
                </Col>

                <Col xs={24}>
                    <FormInput
                        type="file"
                        onChange={e => runInAction(() => {
                            __news.imageToUpload = e.target.files[0]
                            __news.imageToUploadSelected = e.target.files.length > 0
                        })}
                        placeholder="Загрузить изображение"
                        accept=".png, .jpg, .jpeg, image/png, image/jpeg"
                        filesUploaded={__news.imageToUploadSelected}
                    />
                </Col>

                {__news.item?.image &&
                    <Col xs={24}>
                        <img src={__news.item.image.url} alt="" style={{maxWidth: 120}}/>
                    </Col>
                }

                <Col xs={24}>
                    <FormButton
                        onClick={() => {
                            if (__news.item.id > 0) {
                                __news.edit()
                            } else {
                                __news.add()
                            }
                        }}
                    >Сохранить</FormButton>
                </Col>
            </Row>
        </Modal>
    </>
})

export default NewsPage
