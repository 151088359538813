import React, {useEffect} from 'react'
import {observer, Observer} from "mobx-react-lite"
import __global from "../../__global"
import __admins from "./__admins"
import {Col, Popconfirm, Popover, Row} from "antd"
import {formatDate, formatDateWithTime} from "../../_components/__helpers"
import {FormButton, FormInput, FormSelect} from "../../_components/FormElements"
import Rollover from "../../_components/Rollover"
import {runInAction} from "mobx"
import GlobalTableBlock from "../../_components/_table/GlobalTable";

const AdminsPage = observer(() => {
    useEffect(() => {
        __global.getList('admins')
        __global.clearFilters()
    }, [])

    const columns = [
        {
            title: 'ID',
            key: 'id',
            sort: true
        },
        {
            title: 'Логин',
            key: 'login',
            sort: true
        },
        {
            title: 'Имя',
            key: 'name',
            sort: true
        },
        {
            title: 'Действия',
            key: 'actions',
            type: 'customRender',
            render: (_, r) => {
                return (
                    <Observer>
                        {() => (
                            <Row gutter={[9,9]} justify="end">
                                <Col>
                                    <FormButton
                                        onClick={() => {
                                            __admins.getUser(r.id)
                                            __admins.toggleRollover()
                                        }}
                                        className="form__button-orange form__button-small"
                                    >Редактировать</FormButton>
                                </Col>
                                <Col>
                                    <Popconfirm
                                        title={"Вы действительно хотите удалить администратора " + r.login + "?"}
                                        onConfirm={_ => __admins.deleteUser(r.id)}
                                        okText="Да"
                                        cancelText="Нет"
                                    >
                                        <FormButton
                                            className="form__button-danger form__button-small"
                                        >Удалить</FormButton>
                                    </Popconfirm>
                                </Col>
                            </Row>
                        )}
                    </Observer>
                )
            }
        },
    ]

    return <>
        <div>
            <div className="workarea__section">
                <Row gutter={[9,9]}>
                    <Col>
                        <FormButton
                            onClick={() => {
                                __admins.setUser()
                                __admins.toggleRollover()
                            }}
                            className="form__button-blue"
                        >Добавить нового</FormButton>
                    </Col>
                </Row>
            </div>

            <div className="workarea__section users">
                <Row gutter={[9, 9]}>
                    <Col xs={24}>
                        <GlobalTableBlock
                            title="Список администраторов"
                            columns={columns}
                        />
                    </Col>
                </Row>
            </div>
        </div>

        <Rollover
            opened={__admins.rolloverOpened}
            close={_ => __admins.toggleRollover()}
            title="Редактировать администратора"
            onSubmit={e => {
                e.preventDefault()
                __admins.saveUser()
            }}
            size="small"
        >
            <FormInput
                disabled={__global.waitForAnswer || __admins.admin.id > 0}
                onChange={e => runInAction(() => __admins.admin.login = e.target.value)}
                value={__admins.admin.login}
                placeholder="Логин"
            />

            <FormInput

                onChange={e => runInAction(() => __admins.admin.name = e.target.value)}
                value={__admins.admin.name}
                placeholder="Имя"
            />

            <FormInput
                type="password"

                onChange={e => runInAction(() => __admins.admin.password = e.target.value)}
                value={__admins.admin.password}
                placeholder="Пароль"
            />

            <FormInput
                type="password"

                onChange={e => runInAction(() => __admins.admin.confirmPassword = e.target.value)}
                value={__admins.admin.confirmPassword}
                placeholder="Повтор пароля"
            />
        </Rollover>
    </>
})

export default AdminsPage
