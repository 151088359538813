import {makeAutoObservable, runInAction} from "mobx"
import __global from "../../__global"
import {message} from "antd";

class offersController {
    constructor() {
        makeAutoObservable(this)
    }

    item = {
        id: 0,
        label: '',
        description: '',
        tag: '',
        sort: 500,
        image: null,
        image_id: null
    }

    imageToUpload = new DataTransfer()
    imageToUploadSelected = false

    modalOpened = false

    toggleModal(id = null) {
        if (id) {
            this.getOne(id)
        }

        this.modalOpened = !this.modalOpened
        if (!this.modalOpened) {
            this.item = {
                id: 0,
                title: '',
                text: '',
                image: null,
                image_id: null
            }
        }
    }

    getOne(id) {
        __global.request('get', `offers/${id}`)
            .then(result => {
                this.item = result
            })
    }

    add() {
        if (this.item.label === '') {
            return message.error('Необходимо указать название оффера')
        }
        if (this.item.description === '') {
            return message.error('Необходимо указать описание оффера')
        }

        let data = new FormData()
        data.append('label', this.item.label)
        data.append('description', this.item.description)
        data.append('tag', this.item.tag)

        if (this.imageToUploadSelected) {
            data.append('image', this.imageToUpload)
        }

        __global.request('post', 'offers/add', data)
            .then(result => {
                this.imageToUpload = new DataTransfer()
                this.imageToUploadSelected = false
                this.toggleModal()
                __global.getList()
            })
    }

    edit() {
        if (this.item.label === '') {
            return message.error('Необходимо указать название оффера')
        }
        if (this.item.description === '') {
            return message.error('Необходимо указать описание оффера')
        }

        let data = new FormData()
        data.append('id', this.item.id)
        data.append('label', this.item.label)
        data.append('description', this.item.description)
        data.append('tag', this.item.tag)
        data.append('sort', this.item.sort)

        if (this.imageToUploadSelected) {
            data.append('image', this.imageToUpload)
        }

        __global.request('post', 'offers/edit', data)
            .then(result => {
                this.imageToUpload = new DataTransfer()
                this.imageToUploadSelected = false
                this.toggleModal()
                __global.getList()
            })
    }

    delete(id) {
        __global.request('delete', `offers/${id}`)
            .then(result => {
                __global.getList()
            })
    }
}

export default new offersController()
