import React, {useEffect} from 'react'
import {observer, Observer} from "mobx-react-lite"
import __global from "../../__global"
import __offers from "./__offers"
import {Col, Modal, Popconfirm, Popover, Row} from "antd"
import {FormButton, FormInput, FormSelect} from "../../_components/FormElements"
import {runInAction} from "mobx"
import GlobalTableBlock from "../../_components/_table/GlobalTable";

const OffersPage = observer(() => {
    useEffect(() => {
        __global.getList('offers')
        __global.clearFilters()
    }, [])

    const columns = [
        {
            title: 'ID',
            key: 'id',
            sort: true
        },
        {
            title: 'Название',
            key: 'label',
            sort: true
        },
        {
            title: 'Тег',
            key: 'tag',
            sort: true
        },
        {
            title: 'Порядок',
            key: 'sort',
            sort: true
        },
        {
            title: 'Изображение',
            key: 'image',
            type: 'customRender',
            render: (_, r) => {
                if (!_) return <></>

                return (<>
                    <img src={_.url} alt="" style={{maxWidth: 120}}/>
                </>)
            }
        },
        {
            title: 'Действия',
            key: 'actions',
            type: 'customRender',
            render: (_, r) => {
                return (
                    <Observer>
                        {() => (
                            <Row gutter={[9,9]} justify="end">
                                <Col>
                                    <FormButton
                                        onClick={() => __offers.toggleModal(r.id)}
                                        className="form__button-orange form__button-small"
                                    >Редактировать</FormButton>
                                </Col>
                                <Col>
                                    <Popconfirm
                                        title={"Вы действительно хотите удалить оффер " + r.label + "?"}
                                        onConfirm={_ => __offers.delete(r.id)}
                                        okText="Да"
                                        cancelText="Нет"
                                    >
                                        <FormButton
                                            className="form__button-danger form__button-small"
                                        >Удалить</FormButton>
                                    </Popconfirm>
                                </Col>
                            </Row>
                        )}
                    </Observer>
                )
            }
        },
    ]

    return <>
        <div>
            <div className="workarea__section">
                <Row gutter={[9,9]}>
                    <Col>
                        <FormButton
                            onClick={() => __offers.toggleModal()}
                            className="form__button-blue"
                        >Добавить оффер</FormButton>
                    </Col>
                </Row>
            </div>

            <div className="workarea__section users">
                <Row gutter={[9, 9]}>
                    <Col xs={24}>
                        <GlobalTableBlock
                            title="Список офферов"
                            columns={columns}
                        />
                    </Col>
                </Row>
            </div>
        </div>

        <Modal
            title={__offers.item.id > 0 ? 'Редактирование оффера' : 'Создание оффера'}
            open={__offers.modalOpened}
            onCancel={_ => __offers.toggleModal()}
            footer={null}
            className="workarea__modal"
        >
            <Row gutter={[9, 9]}>
                <Col xs={24}>
                    <FormInput
                        onChange={e => runInAction(() => __offers.item.label = e.target.value)}
                        value={__offers.item.label}
                        placeholder="Название"
                    />
                </Col>

                <Col xs={24}>
                    <FormInput
                        onChange={e => runInAction(() => __offers.item.description = e.target.value)}
                        value={__offers.item.description}
                        placeholder="Описание"
                    />
                </Col>

                <Col xs={24}>
                    <FormInput
                        onChange={e => runInAction(() => __offers.item.tag = e.target.value)}
                        value={__offers.item.tag}
                        placeholder="Тег"
                    />
                </Col>

                <Col xs={24}>
                    <label>Порядок сортировки</label>
                    <FormInput
                        onChange={e => runInAction(() => __offers.item.sort = e.target.value)}
                        value={__offers.item.sort}
                        placeholder="Порядок сортировки"
                    />
                </Col>

                <Col xs={24}>
                    <FormInput
                        type="file"
                        onChange={e => runInAction(() => {
                            __offers.imageToUpload = e.target.files[0]
                            __offers.imageToUploadSelected = e.target.files.length > 0
                        })}
                        placeholder="Загрузить изображение"
                        accept=".png, .jpg, .jpeg, image/png, image/jpeg"
                        filesUploaded={__offers.imageToUploadSelected}
                    />
                </Col>

                {__offers.item?.image &&
                    <Col xs={24}>
                        <img src={__offers.item.image.url} alt="" style={{maxWidth: 120}}/>
                    </Col>
                }

                <Col xs={24}>
                    <FormButton
                        onClick={() => {
                            if (__offers.item.id > 0) {
                                __offers.edit()
                            } else {
                                __offers.add()
                            }
                        }}
                    >Сохранить</FormButton>
                </Col>
            </Row>
        </Modal>
    </>
})

export default OffersPage
