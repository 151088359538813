require('./bootstrap')
require('./pages/__pages')

const error = console.error;
console.error = (...args) => {
    if (/defaultValue/.test(args[0]) || /defaultProps/.test(args[0]) || /findDOMNode/.test(args[0])) {
        return
    }
    error(...args)
}
